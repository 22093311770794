html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

@font-face {
  font-family: 'FuturaMaxiCGBold';
  src: url('/fonts/FuturaMaxiCGBold.otf');
}
@font-face {
  font-family: 'OpenSauceTwo-Bold';
  src: url('/fonts/OpenSauceTwo-Bold.ttf');
}
@font-face {
  font-family: 'OpenSauceTwo-Regular';
  src: url('/fonts/OpenSauceTwo-Regular.ttf');
}
@font-face {
  font-family: 'OpenSauceTwo-SemiBold';
  src: url('/fonts/OpenSauceTwo-SemiBold.ttf');
}

.container {
  width: 100%;
}
.display-mobile {
  display: block;
}
.display-desktop {
  display: none;
}
.date-form-control {
  font-size: 16px;
}
@media (min-width: 576px) {
  .container {
    width: 576px;
  }
  .display-mobile {
    display: block;
  }
  .display-desktop {
    display: none;
  }
}
@media (min-width: 1024px) {
  .container {
    width: 1024px;
  }
  .display-mobile {
    display: none;
  }
  .display-desktop {
    display: block;
  }
  .date-form-control {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.pac-container {
  z-index: 10000;
}
.mfa-input {
  -moz-appearance: textfield;
}
.mfa-input ::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
